import React, { useState, useEffect } from "react";

function RadioPieceList({
  handleClick,
  startTime,
  endTime,
  timestamp,
  title,
  students,
  curTime,
}) {
  const studentList = students.map((student) => (
    <p key={student} className="radioNameList">
      {student}
    </p>
  ));

  return (
    <button
      onClick={(e) => handleClick(startTime)}
      className={
        curTime < endTime && curTime > startTime
          ? "radioPiece Selected"
          : "radioPiece"
      }
    >
      <p className="timeStamp">{timestamp}</p>
      <span>
        <h2>{title}</h2>
        {studentList}
      </span>
    </button>
  );
}

export default RadioPieceList;
