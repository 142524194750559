import React, { useState, useEffect } from "react";

import RadioPieceContainer from "../components/radioPieceContainer";

function RadioPieceList({
  setClickedTime,
  setClicked,
  clicked,
  curTime,
  pieceList,
}) {
  function handleClick(time) {
    setClickedTime(time);
    setClicked(!clicked);
  }

  const finalPieceList = pieceList.map((piece) => (
    <RadioPieceContainer
      key={piece.timestamp}
      startTime={piece.start}
      endTime={piece.end}
      timestamp={piece.timestamp}
      title={piece.title}
      students={piece.students}
      handleClick={(time) => handleClick(time)}
      curTime={curTime}
    />
  ));
  return <div className="radioPieceContainer">{finalPieceList}</div>;
}

export default RadioPieceList;
