import React from "react";
import { Link } from "gatsby";

export default function Menu({ highlighted }) {
  return (
    <div className="topBarNav radioNav">
      <Link
        className={
          highlighted ? "menuRadioLink menuRadioActive" : "menuRadioLink"
        }
        activeClassName="menuRadioActive"
        to={`/radio/episode1/`}
      >
        <p>Episode 1</p>
      </Link>

      <Link
        className="menuRadioLink"
        activeClassName="menuRadioActive"
        to={`/radio/episode2/`}
      >
        <p>Episode 2</p>
      </Link>
      <Link
        className="menuRadioLink"
        activeClassName="menuRadioActive"
        to={`/radio/episode3/`}
      >
        <p>Episode 3</p>
      </Link>
    </div>
  );
}
