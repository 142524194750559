import React, { useState } from "react";
import Seo from "../../components/Seo";
import Audio from "../../components/audioplayer/Audio";

import episode2 from "../../assets/radio/Episode2.mp3";
import Layout from "../../components/Layout";

import RadioPieceList from "../../components/radioPieceList";
import RadioNavV2 from "../../components/RadioNavV2";

const Station2 = () => {
  const [curTime, setCurTime] = useState(0);
  const [clickedTime, setClickedTime] = useState(0);
  const [clicked, setClicked] = useState(false);

  function setCurrentTime(time) {
    setCurTime(time);
  }

  return (
    <Layout>
      <div className="radioMain allTop">
        <RadioNavV2 />

        <Seo
          title={`Kingston School of Art Foundation 2021 • Station 2 • Radio`}
        />

        <div className="radioPage">
          <div className="radioInner">
            {/* <h1 className="radioTitle">Title of station 2</h1> */}

            <Audio
              src={episode2}
              time={clickedTime}
              clicked={clicked}
              setCurTime={(time) => setCurrentTime(time)}
            />
            <RadioPieceList
              setClickedTime={(time) => setClickedTime(time)}
              setClicked={(clicked) => setClicked(clicked)}
              clicked={clicked}
              curTime={curTime}
              pieceList={[
                {
                  start: 58,
                  end: 323,
                  timestamp: "00:58",
                  title: "3am Ramblings",
                  students: ["Amarachi Awujo"],
                },
                {
                  start: 331,
                  end: 387,
                  timestamp: "05:31",
                  title: "Advert",
                  students: ["Glump Collective"],
                },
                {
                  start: 402,
                  end: 697,
                  timestamp: "06:42",
                  title: "Re meeting You",
                  students: ["Anna Curtz-Price", "Phoebe Russell"],
                },
                {
                  start: 705,
                  end: 1032,
                  timestamp: "11:45",
                  title: "Cooking With Covid",
                  students: ["Ren Black", "Maya King-Dabbs"],
                },
                {
                  start: 1037,
                  end: 1211,
                  timestamp: "17:17",
                  title: "Listening to Images",
                  students: ["Anna Curtz-Price"],
                },
                {
                  start: 1215,
                  end: 1476,
                  timestamp: "20:15",
                  title: "The Wooden Spoon Idea",
                  students: ["Peter Westall"],
                },
                {
                  start: 1511,
                  end: 2256,
                  timestamp: "25:11",
                  title: "Another Internet",
                  students: ["Theo Hamilton"],
                },
                {
                  start: 2260,
                  end: 2436,
                  timestamp: "37:40",
                  title: "Mars: Part 2",
                  students: [" Aparna Kmitra", "Phoebe Russell", "Sophie Lin"],
                },
              ]}
            />
            <p className="radioCredits">
              Presenter: Joshua Cornwell / Sound Design and Editing: Alex Abrams
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Station2;
